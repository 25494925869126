<template>
  <div class="form-check" >
      <input type='file' ref='fileUpload' @change="submit" accept="image/jpeg, image/png"  style='display:none;' :multiple='multiple' />
      <l-button type="default"  @click="$refs.fileUpload.click()" class='text-right primary btn-sm' >上傳圖片</l-button>
  </div>
</template>
<script>
  import lib from '../../lib'
  export default {
    name: 'imageUpload',
    props: {
      multiple: [Boolean, String],
      inline: Boolean,
      imageVaildation: {
        type: Object,
        default: function () { return {} }
      }
    },
    data () {
      return {
        cbId: ''
      }
    },
    methods: {
      getImageInfo(file) {
        return new Promise( (resolved, reject) => {

          if(!file || file.type.indexOf('image/') !== 0) {
            reject();
            // return;
          }
          var info = {}
          
          info.size = file.size;

          let reader = new FileReader();
      
          reader.readAsDataURL(file);

          reader.onload = evt => {
            let img = new Image();
        
            img.onload = () => {
              info.width = img.width;
              info.height = img.height;
              resolved(info)
            }
            img.src = evt.target.result;
            img.onerror = function(err){
              reject();
            }
          }

          reader.onerror = evt => {
            reject()
          }
        })
      },
      async submit(event){

        const files = event.target.files

        if (files.length == 0) return

        //check multiple max limit
        if (this.multiple && typeof this.imageVaildation.limit !== 'undefined' ){
            if (this.imageVaildation.limit == 0){
              this.$emit('upload', false, `圖片數量已達最大上限`)
              return
            }
            if (this.imageVaildation.limit < files.length){
              this.$emit('upload', false, `圖片數量不能超過 ${this.imageVaildation.limit}</b> 張`)
              return
            }
        }

        for (var i = 0; i < files.length; i++) {
          var file = files[i]
          
          if (this.imageVaildation.size){
            if (file.size > ( this.imageVaildation.size * 1024 * 1024)){
              this.$emit('upload', false, `檔案大小不能超過 <b style='color:red;font-size:larger'>${this.imageVaildation.size}</b> MB`)
              return
            }
          }

          var imgInfo = await this.getImageInfo(file).catch((err) => null)
          if (imgInfo == null) {
            this.$emit('upload', false, '格式錯誤')
            return 
          }
          var errs = []

          if (this.imageVaildation.width){
            if (imgInfo.width !== this.imageVaildation.width){
            errs.push(`寬度應為 <b style='color:red;font-size:larger'>${this.imageVaildation.width}</b>`)
            }
          }

          if (this.imageVaildation.height){
            if (imgInfo.height !== this.imageVaildation.height){
            errs.push(`長度應為 <b style='color:red;font-size:larger'>${this.imageVaildation.height}</b>`)
            }
          }

          if (errs.length > 0){
            this.$emit('upload', false, errs.join('<br>'))
            return 
          }
          await this.s3upload(file)
        };    
        event.target.value = ''
        
      },
      async s3upload(file){
        var ext = file.name.split('.').pop();
        var result = await lib.getPreSignedUrl(ext)
        var contentType = `image/${ext}`

        if (!result || !result.code == 0) {
          this.$emit('upload', false, 'get pre-signed url fail')
          return
        }
        var preSignedUrl = result.data
        var resultS3 = await lib.uploadToS3(preSignedUrl, file, contentType)
        if (resultS3 && resultS3.status == 200){
          this.$emit('upload', true, preSignedUrl.split('?')[0])
        }else {
          this.$emit('upload', false, result.message)
        } 
      },
    },
    created () {
      this.cbId = Math.random().toString(16).slice(2)
    }
  }
</script>
