let Constants = {
  //  install
  install(Vue, options) {
    //  create global Constants
    Vue.Constants = {

      HttpStatus: {
        Success: 200
      },

      Role: {
        Super: 1,
        Admin: 2,
        Member: 3
      },

      Gender: {
        Unknown: 0,
        Male: 1,
        Female: 2
      },

      MemberPageMode: {
        UserList: 0,
        UserData: 1,
        UserAdd: 2
      },

      HospitalPageMode: {
        HospitalList: 0,
        HospitalAdd: 1,
        HospitalData: 2,
        HospitalEdit: 3,
        GroupData: 4,
        GroupEdit: 5,
        GroupMemberAdd: 6,
      },

      GroupPageMode: {
        GroupList: 0,
        GroupData: 1,
        GroupEdit: 2,
        GroupMemberAdd: 3,
      },

      Dataset: {
        GeneralInfoList: 0,
        Data: 1,
        GeneralInfo: 2,
      },
    }
  }
}

export default Constants