<template>
  <div class="row col-12" style="margin:10px 0px;padding: 0px;">
    <div width='200px' style='position:relative;'>
        <input type='text' v-model="model" style='display:none;' @change="reloadVideo">
        <img src="static/img/default-video.jpg" v-show="(model == '' || model == null)" width='300px'>
        <video width="300" controls v-show='model' :src='model' ref="video"></video>
        <br>
        <a v-bind:href="value" target="_blank">
          {{value}}
        </a>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'VideoView',
    props: {
      value: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
      }
    },
    computed: {
      model: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
    methods: {
      reloadVideo(){   
        if (this.model){
          var vid = this.$ref.video
          vid.load()
        }
      }
    }
  }
</script>
<style>
</style>
