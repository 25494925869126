<template>
  <div class="form-check" >
      <!-- <l-button type="default" @click="openModal()" class='text-right'>新增語系</l-button>
      <el-dialog
        center
        title='請選擇語言'
        :append-to-body="true"
        :visible.sync="modals.dialog"> -->
        <div >
          <el-select class="select-default" placeholder="請選擇語言"  v-model="languageId"  @change="selectLanguage()" >
              <el-option v-for="i in getLanguageOptions()" class="select-default" :value="i.id" :label="i.name" :key="i.id">
              </el-option>
          </el-select>
        </div>
      <!-- </el-dialog> -->
  </div>
</template>
<script>
  import { Dialog, Select, Option } from 'element-ui'
  import lib from '../lib'
  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Dialog.name]: Dialog
    },
    name: 'LanguageSelector',
    props: ['value'],
    async mounted (){
      await this.getLanguageList()
    },
    data () {
      return {
        languageId: null,
        languages: [],
        fallback: null,
      }
    },
    watch: {
      value: function (val) {
        if (val.length == 0) {
          this.addDefault()
        }
      }
    },
    methods: {
      // openModal () {
      //   this.modals['dialog'] = true
      // },
      addDefault(){
        if (this.value.length == 0) {
         this.$emit("input", [{ language_id: this.fallback }])   
        } 
      },
      selectLanguage(){
        this.$emit("input", this.value.concat( { language_id: this.languageId }))
        this.languageId = ''
        // this.modals['dialog'] = false
      },
      getLanguageOptions(){
        var _this = this
        return this.languages.filter(i => !_this.value.find((item) => i.id === item.language_id ))
      },

      async getLanguageList(){
        var languageData = await lib.getLanguageList()
        this.languages = languageData.supported
        this.fallback = languageData.fallback
        this.addDefault()
       
      }
    }
  }
</script>
<style>
</style>
