<template>
  <div class="row col-12 col-lg-12" style="margin:10px 0px;padding: 0px;">
    <img src="static/img/default-image.jpg" v-show="value.length == 0" width='200px' >
    <div v-for="(ii, imageIdx) in value" v-bind:key='imageIdx' width='200px' style='position:relative;margin-right: 30px;;margin-bottom: 10px;'>
        <a v-bind:href="ii" target="_blank">
          <img v-bind:key="`${imageIdx}.image`" v-bind:src="ii" class='images'>
        </a>
        <a class="btn btn-danger btn-link btn-xs deleteIcon" @click="handleDeleteImages(imageIdx)" >
          <i class="fa fa-close" style='color:red;'></i>
        </a>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ImagesView',
    props: {
      value: {
        type: Array,
        default: function () { return [] }
      }
    },
    computed: {

    },
    methods: {
      handleDeleteImages (idx){
        this.value.splice(idx, 1)
      },

    }
  }
</script>
<style>

.deleteIcon {
  position: absolute;
  top: -5px;
  right: -10px;
  border-radius: 50%;
  color: red;
}

.images{
  width: 200px;
}
</style>
