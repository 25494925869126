const api = {
  prod: process.env.VUE_APP_ROOT_API,
  dev: process.env.VUE_APP_ROOT_API,

  prodMed: process.env.VUE_APP_MED_API,
  devMed: process.env.VUE_APP_MED_API
}
let apiEndPoint = process.env.NODE_ENV === 'production' ? api.prod : api.dev
if (process.env.API_ENDPOINT) {
  apiEndPoint = process.env.API_ENDPOINT
}
const apiBaseUrl = apiEndPoint

let apiMedEndPoint = process.env.NODE_ENV === 'production' ? api.prodMed : api.devMed
if (process.env.API_MED_ENDPOINT) {
  apiMedEndPoint = process.env.API_MED_ENDPOINT
}
const apiMedBaseUrl = apiMedEndPoint


export default {
  auth: {
    login: { method: 'post', url: `${apiBaseUrl}/user/sign-in` },
    logout: { method: 'post', url: `${apiBaseUrl}/logout` },
  },
  me:{
    show: { method: 'get', url: `${apiBaseUrl}/user/me` },
    update: { method: 'patch', url: `${apiBaseUrl}/user/me` }
  },
  users:{
    list: { method: 'get', url: `${apiBaseUrl}/user` },
    get: { method: 'get', url: `${apiBaseUrl}/user` },
    create: { method: 'post', url: `${apiBaseUrl}/user`},
    update: { method: 'patch', url: `${apiBaseUrl}/user`},
    delete: { method: 'delete', url: `${apiBaseUrl}/users`}
  },
  members:{
    list: { method: 'get', url: `${apiBaseUrl}/members` },
    create: { method: 'post', url: `${apiBaseUrl}/members`},
    update: { method: 'put', url: `${apiBaseUrl}/members`},
    delete: { method: 'delete', url: `${apiBaseUrl}/members`}
  },
  hospitals: {
    list: { method: 'get', url: `${apiBaseUrl}/hospital/index` },
    create: { method: 'post', url: `${apiBaseUrl}/hospital` },
    get: { method: 'get', url: `${apiBaseUrl}/hospital` },
    update: { method: 'patch', url: `${apiBaseUrl}/hospital` },
  },
  groups: {
    get: { method: 'get', url: `${apiBaseUrl}/group` },
    addMember: { method: 'post', url: `${apiBaseUrl}/group/{{groupId}}/user` },
    deleteMember: { method: 'delete', url: `${apiBaseUrl}/group/{{groupId}}/user` },
    update: { method: 'patch', url: `${apiBaseUrl}/group` },
    list: { method: 'get', url: `${apiBaseUrl}/group/index` },
    create: { method: 'post', url: `${apiBaseUrl}/group` },
    delete: { method: 'delete', url: `${apiBaseUrl}/group` }
  },
  dataset: {
    generalInfoList: { method: 'get', url: `${apiMedBaseUrl}/general_info_list` },
    generalInfoListOfUser: { method: 'get', url: `${apiMedBaseUrl}/general_info/user_id` },
    progressNoteListOfUser: { method: 'get', url: `${apiMedBaseUrl}/progress_note/user_id` },
    dischargeNoteListOfUser: { method: 'get', url: `${apiMedBaseUrl}/discharge_note/user_id` },
    admissionNoteListOfUser: { method: 'get', url: `${apiMedBaseUrl}/admission_note/user_id` },
    medicalHistoryListOfUser: { method: 'get', url: `${apiMedBaseUrl}/medical_history/user_id` },
    inspectionReportListOfUser: { method: 'get', url: `${apiMedBaseUrl}/inspection_report/user_id` },
    testReportListOfUser: { method: 'get', url: `${apiMedBaseUrl}/test_report/user_id` },
    surgeryReportListOfUser: { method: 'get', url: `${apiBaseUrl}/dataset/surgery-report` },
  },
  caseParameter: {
    list: { method: 'get', url: `${apiBaseUrl}/case-parameter` },
  },
  support:{
    show: { method: 'get', url: `${apiBaseUrl}/support` },
    update: { method: 'put', url: `${apiBaseUrl}/support`},
    delete: { method: 'delete', url: `${apiBaseUrl}/support`}
  },
  policy:{
    show: { method: 'get', url: `${apiBaseUrl}/policy` },
    update: { method: 'put', url: `${apiBaseUrl}/policy`},
    delete: { method: 'delete', url: `${apiBaseUrl}/policy`}
  },
  activity:{
    list: { method: 'get', url: `${apiBaseUrl}/activities` },
    create: { method: 'post', url: `${apiBaseUrl}/activities`},
    update: { method: 'put', url: `${apiBaseUrl}/activities`},
    delete: { method: 'delete', url: `${apiBaseUrl}/activities`}
  },
  company:{
    list: { method: 'get', url: `${apiBaseUrl}/companies` },
    create: { method: 'post', url: `${apiBaseUrl}/companies`},
    update: { method: 'put', url: `${apiBaseUrl}/companies`},
    delete: { method: 'delete', url: `${apiBaseUrl}/companies`}
  },
  department:{
    list: { method: 'get', url: `${apiBaseUrl}/companies/{{companyId}}/departments` },
    create: { method: 'post', url: `${apiBaseUrl}/companies/{{companyId}}/departments`},
    update: { method: 'put', url: `${apiBaseUrl}/companies/{{companyId}}/departments`},
    delete: { method: 'delete', url: `${apiBaseUrl}/companies/{{companyId}}/departments`}
  },
  tag:{
    list: { method: 'get', url: `${apiBaseUrl}/tags` }
  },

  roles:{
    list: { method: 'get', url: `${apiBaseUrl}/roles` },
    // create: { method: 'post', url: `${apiBaseUrl}/roles`},
    update: { method: 'put', url: `${apiBaseUrl}/roles`},
    // delete: { method: 'delete', url: `${apiBaseUrl}/roles`}
  },

  



  locations:{
    list: { method: 'get', url: `${apiBaseUrl}/locations` },
  },
  languages:{
    list: { method: 'get', url: `${apiBaseUrl}/languages` },
  },
  images:{
    upload: { method: 'post', url: `${apiBaseUrl}/images` },
    preSignedUrl: { method: 'get', url: `${apiBaseUrl}/uploadUrl` },
  }
}
