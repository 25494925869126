import config from './config/api.js'
import Axios from 'axios'
import store from './store/index.js'
import Vue from "vue"

const login = async function (email, password, recaptcha) {
  var setting = config.auth.login
  var data = {
    email: email,
    password: password,
    recaptcha: recaptcha
  }
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const logout = async function () {
  var setting = config.auth.logout
  var result = await callApi(setting.method, setting.url)
  store.commit('del_user')
  return true
}

//================users====================
const getUserList = async function (data = {}) {
  var setting = config.users.list
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const getUserById = async function (id, data = {}) {
  var setting = config.users.get
  var result = await callApi(setting.method, setting.url + "/" + id, data)
  return result
}

const createUser = async function (data = {}) {
  var setting = config.users.create
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const deleteUser = async function (id) {
  var setting = config.users.delete
  var result = await callApi(setting.method, setting.url + "/" + id)
  return result
}

const updateUser = async function (id, data) {
  var setting = config.users.update
  var result = await callApi(setting.method, setting.url + "/" + id, data)
  return result
}

//================me====================
const getMe = async function (data = {}) {
  var setting = config.me.show
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const updateMe = async function (data = {}) {
  var setting = config.me.update
  var result = await callApi(setting.method, setting.url, data)
  return result
}

//================hospital====================
const getHospitalList = async function (data = {}) {
  var setting = config.hospitals.list
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const createHospital = async function (data = {}) {
  var setting = config.hospitals.create
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const getHospital = async function (hospitalId, data = {}) {
  var setting = config.hospitals.get
  var result = await callApi(setting.method, setting.url + "/" + hospitalId, data)
  return result
}

const updateHospital = async function (hospitalId, data = {}) {
  var setting = config.hospitals.update
  var result = await callApi(setting.method, setting.url + "/" + hospitalId, data)
  return result
}

//================group====================
const getGroup = async function (groupId, data = {}) {
  var setting = config.groups.get
  var result = await callApi(setting.method, setting.url + "/" + groupId, data)
  return result
}

const addMember = async function (groupId, userId, data = {}) {
  var setting = config.groups.addMember
  var url = setting.url.replace('{{groupId}}', groupId)
  var result = await callApi(setting.method, url + "/" + userId, data)
  return result
}

const deleteMember = async function (groupId, userId, data = {}) {
  var setting = config.groups.deleteMember
  var url = setting.url.replace('{{groupId}}', groupId)
  var result = await callApi(setting.method, url + "/" + userId, data)
  return result
}

const updateGroup = async function (groupId, data = {}) {
  var setting = config.groups.update
  var result = await callApi(setting.method, setting.url + "/" + groupId, data)
  return result
}

const listGroup = async function (data = {}) {
  var setting = config.groups.list
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const createGroup = async function (data = {}) {
  var setting = config.groups.create
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const deleteGroup = async function (groupId, data = {}) {
  var setting = config.groups.delete
  var result = await callApi(setting.method, setting.url + "/" + groupId, data)
  return result
}

//================policy====================
const getPolicy = async function (data = {}) {
  var setting = config.policy.show
  var result = await callApi(setting.method, setting.url)
  return result
}

const updatePolicy = async function (data = {}) {
  var setting = config.policy.update
  var result = await callApi(setting.method, setting.url, data)
  return result
}

//================data set====================
const generalInfoList = async function (page = 0, data = {}) {
  var setting = config.dataset.generalInfoList
  var result = await callApi(setting.method, setting.url + "/" + page, data)
  return result
}

const generalInfoListOfUser = async function (userId, start, end, page = 0, data = {}) {
  var setting = config.dataset.generalInfoListOfUser
  var result = await callApi(setting.method, setting.url + "/" + userId + "/" + start + "/" + end + "/" + page, data)
  return result
}

const progressNoteListOfUser = async function (userId, start, end, page = 0, data = {}) {
  var setting = config.dataset.progressNoteListOfUser
  var result = await callApi(setting.method, setting.url + "/" + userId + "/" + start + "/" + end + "/" + page, data)
  return result
}

const dischargeNoteListOfUser = async function (userId, start, end, page = 0, data = {}) {
  var setting = config.dataset.dischargeNoteListOfUser
  var result = await callApi(setting.method, setting.url + "/" + userId + "/" + start + "/" + end + "/" + page, data)
  return result
}

const admissionNoteListOfUser = async function (userId, start, end, page = 0, data = {}) {
  var setting = config.dataset.admissionNoteListOfUser
  var result = await callApi(setting.method, setting.url + "/" + userId + "/" + start + "/" + end + "/" + page, data)
  return result
}

const medicalHistoryListOfUser = async function (userId, start, end, page = 0, data = {}) {
  var setting = config.dataset.medicalHistoryListOfUser
  var result = await callApi(setting.method, setting.url + "/" + userId + "/" + start + "/" + end + "/" + page, data)
  return result
}

const inspectionReportListOfUser = async function (userId, start, end, page = 0, data = {}) {
  var setting = config.dataset.inspectionReportListOfUser
  var result = await callApi(setting.method, setting.url + "/" + userId + "/" + start + "/" + end + "/" + page, data)
  return result
}

const testReportListOfUser = async function (userId, start, end, page = 0, data = {}) {
  var setting = config.dataset.testReportListOfUser
  var result = await callApi(setting.method, setting.url + "/" + userId + "/" + start + "/" + end + "/" + page, data)
  return result
}

const surgeryReportListOfUser = async function (data = {}) {
  var setting = config.dataset.surgeryReportListOfUser
  var result = await callApi(setting.method, setting.url, data)
  return result
}

//================support====================
const getCaseParameterList = async function (data = {}) {
  var setting = config.caseParameter.list
  var result = await callApi(setting.method, setting.url, data)
  return result
}

//================support====================
const getSupport = async function (data = {}) {
  var setting = config.support.show
  var result = await callApi(setting.method, setting.url)
  return result
}

const updateSupport = async function (data = {}) {
  var setting = config.support.update
  var result = await callApi(setting.method, setting.url, data)
  return result
}

//================activity====================
const getActivityList = async function (data = {}) {
  var setting = config.activity.list
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const createActivity = async function (data = {}) {
  var setting = config.activity.create
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const deleteActivity = async function (id) {
  var setting = config.activity.delete
  var result = await callApi(setting.method, setting.url + "/" + id)
  return result
}

const updateActivity = async function (id, data) {
  var setting = config.activity.update
  var result = await callApi(setting.method, setting.url + "/" + id, data)
  return result
}

//================member====================
const getMemberList = async function (data = {}) {
  var setting = config.members.list
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const createMember = async function (data = {}) {
  var setting = config.members.create
  var result = await callApi(setting.method, setting.url, data)
  return result
}

// const deleteMember = async function (id) {
//   var setting = config.members.delete
//   var result = await callApi(setting.method, setting.url + "/" + id)
//   return result
// }

const updateMember = async function (id, data) {
  var setting = config.members.update
  var result = await callApi(setting.method, setting.url + "/" + id, data)
  return result
}

//================company====================
const getCompanyList = async function (data = {}) {
  var setting = config.company.list
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const createCompay = async function (data = {}) {
  var setting = config.company.create
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const deleteCompany = async function (id) {
  var setting = config.company.delete
  var result = await callApi(setting.method, setting.url + "/" + id)
  return result
}

const updateCompany = async function (id, data) {
  var setting = config.company.update
  var result = await callApi(setting.method, setting.url + "/" + id, data)
  return result
}

//================department====================

const getDepartmentList = async function (companyId, data = {}) {
  var setting = config.department.list
  var url = setting.url.replace('{{companyId}}', companyId)
  var result = await callApi(setting.method, url, data)
  return result
}

const createDepartment = async function (companyId, data = {}) {
  var setting = config.department.create
  var url = setting.url.replace('{{companyId}}', companyId)
  var result = await callApi(setting.method, url, data)
  return result
}

const deleteDepartment = async function (companyId, id) {
  var setting = config.department.delete
  var url = setting.url.replace('{{companyId}}', companyId)
  var result = await callApi(setting.method, url + "/" + id)
  return result
}

const updateDepartment = async function (companyId, id, data = {}) {
  var setting = config.department.update
  var url = setting.url.replace('{{companyId}}', companyId)
  var result = await callApi(setting.method, url + "/" + id, data)
  return result
}


//================tags====================

const getTagList = async function (data = {}) {
  var setting = config.tag.list
  var result = await callApi(setting.method, setting.url, data)
  return result
}

// const createTag = async function (data = {}) {
//   var setting = config.tag.create
//   var result = await callApi(setting.method, setting.url, data)
//   return result
// }

// const deleteTag = async function (id) {
//   var setting = config.tag.delete
//   var result = await callApi(setting.method, setting.url + "/" + id)
//   return result
// }

// const updateTag = async function (id, data = {}) {
//   var setting = config.tag.update
//   var result = await callApi(setting.method, setting.url + "/" + id, data)
//   return result
// }

//================Rules====================
const getRule = async function () {
  var setting = config.rules.one
  var result = await callApi(setting.method, setting.url)
  return result
}

// const createRules= async function (data = {}) {
//   var setting = config.rules.create
//   var result = await callApi(setting.method, setting.url, data)
//   return result
// }

// const deleteRules = async function (id) {
//   var setting = config.rules.delete
//   var result = await callApi(setting.method, setting.url + "/" + id)
//   return result
// }

const updateRule = async function (data) {
  var setting = config.rules.update
  var result = await callApi(setting.method, setting.url, data)
  return result
}


//================roles====================
const getRoleList = async function () {
  var setting = config.roles.list
  var result = await callApi(setting.method, setting.url)
  return result
}

// const createRules= async function (data = {}) {
//   var setting = config.rules.create
//   var result = await callApi(setting.method, setting.url, data)
//   return result
// }

// const deleteRules = async function (id) {
//   var setting = config.rules.delete
//   var result = await callApi(setting.method, setting.url + "/" + id)
//   return result
// }

const updateRole = async function (id, data) {
  var setting = config.roles.update
  var result = await callApi(setting.method, setting.url + "/" + id, data)
  return result
}

//================memberRegistration====================

const getMemberRegistration = async function () {
  var setting = config.memberRegistration.one
  var result = await callApi(setting.method, setting.url)
  return result
}

// const createMemberRegistration= async function (data = {}) {
//   var setting = config.memberRegistration.create
//   var result = await callApi(setting.method, setting.url, data)
//   return result
// }

// const deleteMemberRegistration = async function (id) {
//   var setting = config.memberRegistration.delete
//   var result = await callApi(setting.method, setting.url + "/" + id)
//   return result
// }

const updateMemberRegistration = async function (data) {
  var setting = config.memberRegistration.update
  var result = await callApi(setting.method, setting.url, data)
  return result
}

//================Language====================
const getLanguageList = async function () {

  var jsonString = store.state.languageData
  var data = (jsonString !== null)? JSON.parse(jsonString): null

  if (data == null){
    var setting = config.languages.list
    var result = await callApi(setting.method, setting.url)
    if (result.success){
        data = result.data
        store.commit('set_language', JSON.stringify(data))
    }
  }
  return data
}
//================location====================
const getLocationList = async function () {
  var jsonString = store.state.locations
  var data = (jsonString !== null)? JSON.parse(jsonString): null

  if (data == null){
    var setting = config.locations.list
    var result = await callApi(setting.method, setting.url)
    if (result.success){
        data = result.data
        store.commit('set_location', JSON.stringify(data))
    }
  }
  return data
}

//================image====================

const uploadImage = async function (file) {
  var formData = new FormData();
  var setting = config.images.upload
  formData.append("image", file);
  return Axios({
    method: setting.method,
    url: setting.url,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(function(res){
    return res.data
  }).catch(function(error){
    return error.data
  })
}

const getPreSignedUrl = async function (ext) {
  var setting = config.images.preSignedUrl
  var data = { extension: ext }
  var result = await callApi(setting.method, setting.url, data)
  return result
}

const uploadToS3 = async function (url , file, contentType = '', onProgress) {
  const instance = Axios.create()
  instance.defaults.headers.common = {};

  var config = {
    method: 'PUT',
    url: url,
    data: file,
    onUploadProgress(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) /
        progressEvent.total)
      if (onProgress) onProgress(percentCompleted)
      return percentCompleted;
    }
  }

  if (contentType !== '') {
    var headers = {}
    headers['content-type'] = contentType
    config.headers = headers
  }
  
  return instance.request(config)
    .then(function(res){
    return res
  }).catch(function(error){
    return res
  })
}


//===================================

const callApi = function (method, url, sendData = {}) {
  console.log('callApi:', method, url, sendData)
  var data = JSON.parse(JSON.stringify(sendData))
  Object.keys(data).forEach(key => (data[key] === undefined) ? delete data[key] : null)
  if (method == 'get' && Object.keys(data).length > 0) {
    Object.keys(data).forEach(key => (data[key] === null || data[key] === '') ? delete data[key] : null)
    var queryArr = []
    for (let elem in data) {
      queryArr.push(`${elem}=${data[elem]}`)
    }
    url = url + "?" + queryArr.join('&')
  }
  return Axios({
    method: method,
    url: url,
    data: data
    // headers: {
    //   'Access-Control-Allow-Origin': '*'
    //   // 'Authorization': 'Bearer '
    // }
  }).then(function(res){
    console.log('callApi, res:', res) // TODO: remove later
    // return res.data
    return res
  }).catch(function(error){
    console.log('callApi, error:', error) // TODO: remove later
    // return error.data
    return error
  })
}

const clearEmpties = (o) => {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      continue // If null or not an object, skip to the next iteration
    }

    if (Object.keys(o[k]) === '') {
      delete o[k]; // The object had no properties, so delete that property
    }
    // The property is an object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
}

const imageParse = (translationsData, imageMax = 3) => {
  var translations = JSON.parse(JSON.stringify(translationsData))
    translations.forEach(function (translation, key) {
      var images = []
      for (let i = 0; i < imageMax; i++) {
        if (translation[`image${i + 1}`]) images.push(translation[`image${i + 1}`])        
      }
      translation.images = images
    });
  return translations
}

const imageApiParse = (translationsData, imageMax = 3) => {
  var translations = JSON.parse(JSON.stringify(translationsData))
  translations.forEach(function (item, key) {
    for(let i = 0 ; i < imageMax ; i++){
      item['image' + (i + 1)] = (item.images[i])?item.images[i]:null
    }
    // delete item.images
  });
  return translations
}

const JSONtoCSV = (arr, columns, delimiter = ',') =>
  [
    columns.join(delimiter),
    ...arr.map(obj =>
      columns.reduce(
        (acc, key) => `${acc}${!acc.length ? '' : delimiter}"${!obj[key] ? '' : !Array.isArray(obj[key]) ? obj[key] : obj[key].join('\n')}"`,
        ''
      )
    )
  ].join('\n')

const getUser = function () {
  return JSON.parse(store.state.user)
}

const userIsSuper = function () {
  const user = getUser()
  if (!user) return false
  return user.role == Vue.Constants.Role.Super
}

const userIsAdmin = function () {
  const user = getUser()
  if (!user) return false
  return user.role == Vue.Constants.Role.Admin
}

const checkScope = function ( requiredScopes = [] ) {
  return true // TODO: remove later
  const user = getUser()
  if (!user) return false
  if (user.role_id == 1) return true
  const intersection = requiredScopes.filter(element => user.scopes.includes(element))
  return (intersection.length > 0)
}


const getLanguageText = function (lang) {
  var jsonString = store.state.languageData
  var languageData = (jsonString !== null)? JSON.parse(jsonString): null
  var language = languageData.supported.find( (language) => language.id == lang)

  if (language == null) return lang
  return language.name
}

//================= date ==================
const getCurrentDateInfo = function() {
  let now = new Date()
  // let text =  now.toISOString().split('T')[0]
  const dateInfo = {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate()
  }
  return dateInfo
}

const getDateDiffInMonth = function(baseDate, targetDate) {
  const baseYear = baseDate.getFullYear()
  const baseMonth = baseDate.getMonth() + 1
  const targetYear = targetDate.getFullYear()
  const targetMonth = targetDate.getMonth() + 1
  return targetYear*12 + targetMonth - baseYear*12 - baseMonth
}

const getStartDateText = function(dateInfo, timelineOffset) {
  const monthText = (dateInfo.month < 10) ? "0" + dateInfo.month : "" + dateInfo.month
  const dayText = (dateInfo.day < 10) ? "0" + dateInfo.day : "" + dateInfo.day
  return "" + (dateInfo.year - (2 * (timelineOffset + 1))) + "-" + monthText + "-" + dayText
}

const getEndDateText = function(dateInfo, timelineOffset) {
  const monthText = (dateInfo.month < 10) ? "0" + dateInfo.month : "" + dateInfo.month
  const dayText = (dateInfo.day < 10) ? "0" + dateInfo.day : "" + dateInfo.day
  return "" + (dateInfo.year - 2 * timelineOffset) + "-" + monthText + "-" + dayText
}

export default {
  getUserList: getUserList,
  getUserById: getUserById,
  createUser: createUser,
  deleteUser: deleteUser,
  updateUser: updateUser,

  getCompanyList: getCompanyList,
  createCompany: createCompay,
  updateCompany: updateCompany,
  deleteCompany: deleteCompany,

  getMe: getMe,
  updateMe: updateMe,

  getHospitalList: getHospitalList,
  createHospital: createHospital,
  getHospital: getHospital,
  updateHospital: updateHospital,

  getGroup: getGroup,
  addMember: addMember,
  deleteMember: deleteMember,
  updateGroup: updateGroup,
  listGroup: listGroup,
  createGroup: createGroup,
  deleteGroup: deleteGroup,

  generalInfoList: generalInfoList,
  generalInfoListOfUser: generalInfoListOfUser,
  progressNoteListOfUser: progressNoteListOfUser,
  dischargeNoteListOfUser: dischargeNoteListOfUser,
  admissionNoteListOfUser: admissionNoteListOfUser,
  medicalHistoryListOfUser: medicalHistoryListOfUser,
  inspectionReportListOfUser: inspectionReportListOfUser,
  testReportListOfUser: testReportListOfUser,
  surgeryReportListOfUser: surgeryReportListOfUser,

  getCaseParameterList: getCaseParameterList,

  getPolicy: getPolicy,
  updatePolicy: updatePolicy,

  getSupport: getSupport,
  updateSupport: updateSupport,

  getActivityList: getActivityList,
  createActivity: createActivity,
  updateActivity: updateActivity,
  deleteActivity: deleteActivity,

  getDepartmentList: getDepartmentList,
  createDepartment: createDepartment,
  updateDepartment: updateDepartment,
  deleteDepartment: deleteDepartment,

  getTagList:getTagList,

  getMemberList: getMemberList,
  createMember: createMember,
  updateMember: updateMember,
  // deleteMember: deleteMember,

  getRoleList: getRoleList,
  updateRole: updateRole,

  checkScope: checkScope,

  // imageParse:imageParse,
  // imageApiParse:imageApiParse,

  uploadImage: uploadImage,
  getPreSignedUrl: getPreSignedUrl,
  uploadToS3: uploadToS3,
  getLanguageText: getLanguageText,
  callApi: callApi,
  login: login,
  logout: logout,
  getUser: getUser,
  userIsSuper: userIsSuper,
  userIsAdmin: userIsAdmin,

  getCurrentDateInfo: getCurrentDateInfo,
  getDateDiffInMonth: getDateDiffInMonth,
  getStartDateText: getStartDateText,
  getEndDateText: getEndDateText
}
