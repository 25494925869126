<template>
  <div class="form-check" >
      <input type='file' ref='fileUpload' @change="submit"  style='display:none;' :accept="accept"/>
      <l-button type="default"  @click="$refs.fileUpload.click()" class='text-right primary btn-sm' :loading="loading">上傳檔案</l-button>
      <l-progress :value="processBar" v-if="loading"></l-progress>
  </div>
</template>
<script>
  import lib from '../../lib'
  import Progress from '../Progress'
  import mime from 'mime-types'
  export default {
    name: 'fileUpload',
    
    props: {
      inline: Boolean,
      accept: String,
    },
    data () {
      return {
        loading: false,
        processBar: 0,
        cbId: ''
      }
    },
    components: {
      LProgress: Progress
    },
    methods: {
      onProgress(percent){
        this.processBar = percent
      },
      async submit(event){
        this.loading = true
        const file = event.target.files[0]
        await this.s3upload(file)
        event.target.value = ''
        this.loading = false
      },
      async s3upload(file){
        var ext = file.name.split('.').pop();
        var result = await lib.getPreSignedUrl(ext)
        if (!result || !result.success) {
          this.$emit('upload', false, 'get pre-signed url fail')
          return
        }
        var contentType = mime.lookup(ext)

        if (contentType === false) {
          this.$emit('upload', false, 'can`t get content type')
          return
        }
        var preSignedUrl = result.data
        var resultS3 = await lib.uploadToS3(preSignedUrl, file, contentType, this.onProgress)
        if (resultS3 && resultS3.status == 200){
          this.$emit('upload', true, preSignedUrl.split('?')[0])
        }else {
          this.$emit('upload', false, result.message)
        } 
        this.processBar = 0
      },
    },
    created () {
      this.cbId = Math.random().toString(16).slice(2)
    }
  }
</script>
